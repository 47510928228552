
import React from "react"

import ProductBasicEditorPage from "../../components/negosyonow/controls/productbasiceditor"

const webappsAPI = require("../../../lib/requestWebapps");


const formFields = [
	[
		{"label":"Primary Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
		{"label":"Full Name / Display", "field": "nnproduct_fullname", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Has Page", "field": "nnproduct_haspage", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Webpage", "field": "nnproduct_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
		{"label":"Last Updated", "field": "nnproduct_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Promo Image", "field": "nnproduct_promoimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/product/promo"},
	]
];

const subformFields = [
	{
		"subformid": "nnproductimage",
		"label": "Images",
		"table": "nnproductimage",
		"sort": "nnproductimage_primary desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnproductimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/product/main/full", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 2048,	// OPTIONAL: Will be required for some imagemode
			},
			{
				"label": "Description",
				"field": "nnproductimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Main Imaage",
				"field": "nnproductimage_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal",
				"info": "Only one main image"
			},
		],
		"footerlist": [
		]
	}
];

const ProductimagePage = ({location}) => {

	return <ProductBasicEditorPage
				location={location}
				formFields={formFields}
				editSubFormFields={subformFields}
			/>
}


export default ProductimagePage;
